import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyA59NEZMTovCekeu2oLbBmo9G5l0bIHmgU",
  authDomain: "the-next-ebc73.firebaseapp.com",
  projectId: "the-next-ebc73",
  storageBucket: "the-next-ebc73.appspot.com",
  messagingSenderId: "1020251231251",
  appId: "1:1020251231251:web:008ba174f42bee330a9be6",
  measurementId: "G-EDJBK2NB38"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { app, db };