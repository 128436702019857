import React from 'react'
import {
  Button,
} from "@material-tailwind/react";
import './Main.css'

const Banner = () => {
  return (
    <div className="banner-image flex flex-col items-center justify-center">
      <div className="banner-text z-10">
        <div className='text-center mb-4'>
          <h2>Mouth-watering local dishes,</h2>
          <h2>and continental favorites!</h2>
        </div>
        <div className="button-container flex flex-col md:flex-row justify-center md:space-x-4 space-y-4 md:space-y-0">
          <Button className="bg-[#FB8C00] w-auto text-sm">
            <a href="#sectionmenu" className="text-white">View Menu</a>
          </Button>
          <Button className="bg-transparent border border-r-2-white w-auto text-sm">
            <a href="#sectioncontact" className="text-white">Contact us</a>
          </Button>
        </div>
      </div>
    </div>


  )
}

export default Banner